
import { computed, defineComponent, ref, Ref, onMounted, reactive, onUnmounted } from 'vue';
import { typeChecker } from '@re/utilities';
import { addTextWatermark, dataURLtoFile } from '@re/canvas-utilities';
import { useMutation, useMutationLoading, useQuery } from '@vue/apollo-composable';
import { Toast, Radio, RadioGroup, ImagePreview } from 'vant';

import router, { RouteRecord } from '@/router';
import { applyForm, googleProfile } from '@/composable/auth';
import { imageUpload, fileLoading } from '@/composable/file';
import { $apollo } from '@/plugins/vue-apollo';
import { authMutation } from '@/apollo/mutations';
import { authQuery, parameterQuery } from '@/apollo/queries';

import VanFieldDate from '@/components/VanFieldDate.vue';
import { ApplyFormStatusEnum, ApplyFormVM, UserVM } from '@/view-models/auth.vm';

export default defineComponent({
  components: {
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanFieldDate,
  },
  setup() {
    const form: Ref<any> = ref(null);
    const showPicker = ref(false);
    const promotionUser: Ref<UserVM | null> = ref(null);

    const { result } = useQuery<{ cities: { name: string; list: { name: string; zipCode: string }[] }[] }>(
      parameterQuery.getCities,
      reactive({}),
      { fetchPolicy: 'cache-first' },
    );
    const cities: any = computed(() => result.value?.cities ?? null);

    const fileList: any = computed(() => {
      if (fileLoading.value) {
        return [{ url: '', status: 'uploading', message: '上傳中...' }];
      }
      return applyForm.value.certificateImageUrl ? [{ url: applyForm.value.certificateImageUrl }] : [];
    });
    const applyFormParam = computed(() => {
      const {
        id,
        callingCode,
        mobile,
        dateBirth,
        name,
        gender,
        city,
        region,
        address,
        inviterPromoteCode,
        certificateImageUrl,
      } = applyForm.value;

      const param = {
        applyFormId: id,
        callingCode,
        mobile,
        gender,
        dateBirth,
        name,
        city: city || '',
        region: region || '',
        address: address || '',
        certificateImageUrl,
        inviterPromoteCode,
      };

      return param;
    });
    const isPending = computed(() => ApplyFormStatusEnum.pending === applyForm.value.status);
    const isDraft = computed(() => ApplyFormStatusEnum.draft === applyForm.value.status);
    const isDeny = computed(() => ApplyFormStatusEnum.deny === applyForm.value.status);

    const { mutate: updateApplyFormMutate } = useMutation(authMutation.updateApplyForm);
    const { mutate: confirmApplyFormMutate, called } = useMutation<{ confirmApplyForm: ApplyFormVM }>(
      authMutation.confirmApplyForm,
      {
        variables: {
          id: applyForm.value.id,
        },
      },
    );
    const isLoading = useMutationLoading();

    onMounted(() => {
      window.onbeforeunload = () => {
        if (isDraft.value) {
          updateApplyForm();
        }
      };

      if (applyForm.value.inviterPromoteCode) {
        form.value?.validate('inviterPromoteCode');
      }
    });
    onUnmounted(() => {
      window.onbeforeunload = null;

      if (isDraft.value) {
        updateApplyForm();
      }
    });

    const beforeRead = (file): Promise<any> =>
      new Promise(async (resolve, reject) => {
        if (file.size > 1024 * 1024 * 3) {
          Toast('圖片檔案大小請不要超過 3MB');
          reject();
        } else {
          const imageUrl = await addTextWatermark(file, '限申請阿爾伊股份有限公司推薦平台使用');
          const newFile = dataURLtoFile(imageUrl, file.name);

          const url = await imageUpload({
            image: newFile,
            mimetype: newFile.type,
            size: 'M',
          });

          if (!url) return reject();

          applyForm.value.certificateImageUrl = url;
          updateApplyForm();

          resolve(true);
        }
      });

    async function cityRegionValidator() {
      const { city, region } = applyForm.value;
      return city && region ? true : '請選擇縣市行政區';
    }

    async function mgmValidator(value: string): Promise<boolean | string> {
      if (typeChecker.isNullOrUndefinedOrWhiteSpace(value)) {
        promotionUser.value = null;
        return true;
      }

      try {
        const { data } = await $apollo.query<{
          checkUserExistByPromoteCode: { isExist: boolean; promotionUser: UserVM };
        }>({
          query: authQuery.checkUserExistByPromoteCode,
          fetchPolicy: 'cache-first',
          variables: {
            promoteCode: value,
          },
        });
        const { isExist, promotionUser: newPromotionUser } = data.checkUserExistByPromoteCode;
        promotionUser.value = newPromotionUser;

        return isExist ? isExist : '推薦代碼不存在，請輸入正確的推薦代碼。';
      } catch (err) {
        promotionUser.value = null;
        if (err instanceof Error) return err.message;
        return `${err}`;
      }
    }

    function updateApplyForm() {
      if (called.value) {
        return;
      }

      return updateApplyFormMutate({
        param: applyFormParam.value,
      });
    }

    async function onSubmit() {
      try {
        await updateApplyForm();

        if (isDraft.value || isDeny.value) {
          // FIXME: add type of data ???
          // @ts-ignore
          const { data } = await confirmApplyFormMutate();
          if (data?.confirmApplyForm) {
            applyForm.value = data.confirmApplyForm;
          }
        } else {
          Toast({
            type: 'success',
            message: '資料修改成功。',
          });
        }

        router.push({ name: RouteRecord.SignUpDone.name });
      } catch (err) {
        console.log(err);
      }
    }

    function onPickerConfirm(value: { name: string }[]) {
      const [city, region] = value;
      applyForm.value.city = city.name;
      applyForm.value.region = region.name;
      showPicker.value = false;
    }

    function previewCertificateImage() {
      ImagePreview({
        images: [`${process.env.BASE_URL}certificate-preview.png`],
        showIndex: false,
        closeable: true,
        // className: 'px-4 py-16',
        overlayStyle: {
          backgroundColor: 'rgba(0, 0, 0, .5)',
        },
      });
    }

    return {
      form,
      applyForm,
      googleProfile,
      fileList,
      promotionUser,
      showPicker,
      isLoading,
      isPending,
      isDraft,
      isDeny,
      cities,

      cityRegionValidator,
      mgmValidator,
      beforeRead,
      onSubmit,
      onPickerConfirm,
      previewCertificateImage,
    };
  },
});
