import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_field, {
      modelValue: _ctx.dateLabel,
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      rules: _ctx.rules,
      required: _ctx.required,
      clickable: !_ctx.readonly,
      "right-icon": _ctx.readonly ? '' : 'arrow-down',
      size: _ctx.size,
      readonly: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.readonly ? '' : (_ctx.showPicker = true)))
    }, null, 8, ["modelValue", "name", "label", "placeholder", "rules", "required", "clickable", "right-icon", "size"]),
    _createVNode(_component_van_popup, {
      show: _ctx.showPicker,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showPicker) = $event)),
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_datetime_picker, {
          modelValue: _ctx.pcikerDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pcikerDate) = $event)),
          type: "date",
          title: _ctx.label,
          "min-date": _ctx.minDate,
          "max-date": _ctx.maxDate,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPicker = false))
        }, null, 8, ["modelValue", "title", "min-date", "max-date", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}