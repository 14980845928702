
import { computed, defineComponent, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      default: '日期',
    },
    placeholder: {
      type: String,
      default: '請點擊選擇日期',
    },
    size: {
      type: String,
      default: 'large',
    },
    rules: {
      type: Array,
    },
    required: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const showPicker = ref(false);
    const date = useVModel(props, 'modelValue', emit);
    const pcikerDate = ref(date.value ? new Date(date.value) : new Date());
    const dateLabel = computed(() => (date.value ? dayjs(date.value).format('YYYY/MM/DD') : ''));

    const minDate = dayjs('1917-01-01').toDate();
    const maxDate = dayjs()
      .subtract(20, 'years')
      .toDate();

    function onConfirm() {
      console.log(+pcikerDate.value);
      date.value = dayjs(+pcikerDate.value).toISOString();
      showPicker.value = false;
    }

    return {
      showPicker,
      date,
      dateLabel,
      pcikerDate,
      minDate,
      maxDate,

      onConfirm,
    };
  },
});
